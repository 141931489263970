import Image from 'next/image';
import Link from 'next/link';
import { MinProductItemProps } from "./constants"
import { useEffect, useState } from 'react';
import { AttributeValueItem } from '@/lib/constants';
import { useAttributesStore } from '@/stores/useAttributesStore';
import { motion } from 'framer-motion';
import { get_original_price, get_sales_price } from '@/lib/Definitions';
import { motionVariants } from '../Common/GlobalVars';


const MinProduct = (props: MinProductItemProps) => {
  const { attribute_value_list, cart_image, image, name, url, qty, slug, product_variant_list, attrId, customName } = props;
  const { attributes, getAttributes } = useAttributesStore();
  const [showImage, setShowImage] = useState(image);
  const [selectedAttr, setSelectedAttr] = useState(attrId);
  const [attributesData, setAttributesData] = useState(getAttributes());

  useEffect(() => {
    if (selectedAttr !== null || attrId !== null) {
      const variants = product_variant_list.filter(variant => variant.attribute_value_list.indexOf(attrId !== null ? attrId : selectedAttr !== null ? selectedAttr : 0) !== -1);

      if (variants.length > 0) {
        setShowImage(variants[0].cart_image);
      } else {
        setShowImage(image);
      }
    } else {
      setShowImage(image);
    }
  }, [selectedAttr, attrId, image, product_variant_list]);

  useEffect(() => {
    setAttributesData(getAttributes());
  }, [getAttributes, attributes]);

  const render_product_color = () => {
    const color_list: Array<AttributeValueItem> = [];

    attributesData.data.map((attributes, index) => {
      if (attributes.slug === "color") {
        const filtered = attributes.attribute_values.filter((attribute) => attribute_value_list.some((value) => attribute.id === value)).sort((a, b) => (a.order === b.order) ? a.id - b.id : a.order - b.order);

        if (filtered.length > 0) {
          filtered.map((color) => {
            color_list.push(color);
          });
        }
      }
    });

    if (color_list.length > 1) {
      return (
        <div className="flex flex-wrap justify-start mt-2">
          {color_list.map((color, index) => (
            <Image
              src={color.image}
              alt={color.name + " image"}
              width={14}
              height={14}
              quality={100}
              key={index}
              className={`rounded-full cursor-pointer my-2 mr-4`}
              onClick={() => setSelectedAttr(color.id)}
            />
          ))}
        </div>
      )
    } else {
      return (<></>)
    }
  };

  const show_sales_price = () => {
    const sales_price = get_sales_price(props, null);

    if (Array.isArray(sales_price.price)) {
      return (
        <p className={`flex font-bold text-price`}>
          {process.env.NEXT_PUBLIC_CURRENCY}{sales_price.price[0].toLocaleString()} ~
        </p>
      )
    } else {
      return (
        <p className={`flex font-bold ${sales_price.promotion ? "text-mooimom" : "text-price"}`}>
          {process.env.NEXT_PUBLIC_CURRENCY}{sales_price.price.toLocaleString()}
        </p>
      )
    }
  }

  const show_origin_price = () => {
    const origin_price = get_original_price(props, null);

    if (origin_price === null) {
      return (<p>&nbsp;</p>)
    } else {
      return (
        <p className="font-medium line-through text-originPrice">
          {process.env.NEXT_PUBLIC_CURRENCY}{origin_price.toLocaleString()}
        </p>
      )
    }
  }

  return (
    <motion.div
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true }}
      variants={motionVariants}
      className="rounded shadow-[0_5px_15px_-5px_rgb(0,0,0,0.1)]"
    >
      <Link href={url !== null && url !== undefined && url.length > 0 ? url : "/product/" + slug}>
        <div className="relative pt-[100%] block">
          {props.product_label_right_product_page !== null && props.product_label_right_product_page !== undefined && props.product_label_right_product_page.length > 0 && (
            <Image
              src={props.product_label_right_product_page}
              alt={name + " label"}
              quality={100}
              width={45}
              height={24}
              className="absolute top-0 right-0 z-10 md:w-[50px]"
            />
          )}
          {props.product_label_bottom_right_category_page !== null && props.product_label_bottom_right_category_page !== undefined && props.product_label_bottom_right_category_page.length > 0 && (
            <Image
              src={props.product_label_bottom_right_category_page}
              alt={name + " label"}
              quality={100}
              width={45}
              height={45}
              className="absolute bottom-0 right-0 z-10"
            />
          )}
          <Image
            src={showImage}
            alt={name}
            quality={100}
            width={200}
            height={200}
            className="absolute top-0 left-0 object-contain w-full h-full align-bottom transition duration-500 rounded-t product-img"
          />
          {props.product_label_bottom_left_category_page !== null && props.product_label_bottom_left_category_page !== undefined && props.product_label_bottom_left_category_page.length > 0 && (
            <Image
              src={props.product_label_bottom_left_category_page}
              alt={name + " label"}
              quality={100}
              width={130}
              height={25}
              className="absolute bottom-0 left-0 z-10"
            />
          )}
          {qty === 0 && (<>
            <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-30 rounded-t z-[10]" />
            <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-50 z-[15] p-2 text-center text-white font-poppins font-semibold text-xs">
              Produk Belum Tersedia
            </div>
          </>)}
        </div>
        <div className="px-2 pb-4">
          <p className="h-8 mt-1 mb-2 text-xs font-medium text-black capitalize line-clamp-2 text-ellipsis font-poppins">
            {customName === null ? name : name + " - " + customName}
          </p>
          <div className="flex justify-between">
            <div className="text-sm font-poppins">
              {show_sales_price()}
              {show_origin_price()}
            </div>
            <button
              className="z-10 p-1 cursor-pointer w-fit h-fit"
              onClick={(event) => {
                event?.preventDefault();
                props.setSelectProduct(props);
              }}
            >
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/cart-plus.png"}
                alt="add to cart icon"
                width={20}
                height={20}
                quality={100}
                className=""
              />
            </button>
          </div>
        </div>
      </Link>
      {/* {brand === "MOOIMOM" && render_product_color()} */}
    </motion.div>
  )
}

export default MinProduct
