import Image from 'next/image';
import Link from 'next/link';
import { MultiSubMenuItemProps } from './Utils/constants';
import AccordionMenu from './Utils/AccordionMenu';
import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { motionVariants } from './Common/GlobalVars';
import { useUserStore } from '@/stores/useUserStore';


const subMenu = [
  {
    title: "Tentang",
    sub_menu: [
      {
        title: "Tentang Mooimom",
        link: "/about-mooimom"
      },
      {
        title: "Lokasi Toko",
        link: "/store-locations"
      },
      {
        title: "MOOIMOM Wholesale",
        link: "/wholesale"
      },
      {
        title: "MOOIMOM Affiliate Program",
        link: "#"
      },
    ],
    sub_icon: null,
    multi_menu: null,
  },
  {
    title: "Bantuan",
    sub_menu: [
      {
        title: "Hubungi Kami",
        link: "/contact-us"
      },
      {
        title: "Pengiriman",
        link: "/delivery-information"
      },
      {
        title: "Penukaran Produk",
        link: "/return-and-exchange"
      },
      {
        title: "Garansi Produk",
        link: "/user-warranty-info"
      },
      {
        title: "Kebijakan Privasi",
        link: "/privacy-policy"
      },
      {
        title: "Informasi Cicilan",
        link: "/installment"
      },
      {
        title: "MOOIMOM Rewards",
        link: "/rewards"
      },
      {
        title: "Refer a Friend",
        link: "/user?action=my_referral"
      }
    ],
    sub_icon: null,
    multi_menu: null,
  },
  {
    title: null,
    sub_menu: null,
    sub_icon: null,
    multi_menu: [
      {
        title: "Installlment (0%)",
        sub_menu: null,
        sub_icon: [
          process.env.NEXT_PUBLIC_STATIC_SITE + "/images/payment/hsbc.png",
          process.env.NEXT_PUBLIC_STATIC_SITE + "/images/payment/danamon.png",
          process.env.NEXT_PUBLIC_STATIC_SITE + "/images/payment/standard.png",
          process.env.NEXT_PUBLIC_STATIC_SITE + "/images/payment/permata.png",
        ],
      },
      {
        title: "Payment Method",
        sub_menu: null,
        sub_icon: [
          process.env.NEXT_PUBLIC_STATIC_SITE + "/images/payment/mandiri.png",
          process.env.NEXT_PUBLIC_STATIC_SITE + "/images/payment/bca.png",
          process.env.NEXT_PUBLIC_STATIC_SITE + "/images/payment/spay.png",
          process.env.NEXT_PUBLIC_STATIC_SITE + "/images/payment/atome.png",
          process.env.NEXT_PUBLIC_STATIC_SITE + "/images/payment/mastercard.png",
          process.env.NEXT_PUBLIC_STATIC_SITE + "/images/payment/atm.png",
          process.env.NEXT_PUBLIC_STATIC_SITE + "/images/payment/gopay.png",
        ],
      },
    ]
  },
  {
    title: "Service",
    sub_menu: [
      {
        title: "E-mail: cs@mooimom.id",
        link: null
      },
      {
        title: "Layanan Pelanggan: (021) 24520868",
        link: null
      },
      {
        title: "Jam Operasional:",
        link: null
      },
      {
        title: "Senin - Jumat (09.30 - 17:30) WIB",
        link: null
      }
    ],
  }
]

const Footer = () => {
  const { userInfo, getUser } = useUserStore();
  const [userData, setUserData] = useState(getUser());
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const render_multi_menu = (menu: Array<MultiSubMenuItemProps>) => {
    return menu.map((multi, index) => (
      <div key={index} className={`${index > 0 ? "mt-12" : ""}`}>
        <div className="text-base font-semibold">{multi.title}</div>
        <div className="mt-4 space-y-4">
          {multi.sub_menu && multi.sub_menu.map((sub, index) => (
            <div className="text-xs" key={index}>
              {sub.link !== null ? (
                <Link href={sub.link} key={index}>{sub.title}</Link>
              ) : <>{sub.title}</>}
            </div>
          ))}
          {multi.sub_icon && (
            <div className="grid grid-cols-4 gap-x-6 gap-y-4">
              {multi.sub_icon.map((icon, index) => (
                <Image src={icon} alt="payment icon" key={index} width={50} height={21} quality={100} className="" />
              ))}
            </div>
          )}
        </div>
      </div>
    ))
  }

  const setOpen = (index: number, open: boolean) => {
    if (open) {
      setOpenIndex(index);
    } else {
      setOpenIndex(null);
    }
  }

  const render_mobile_menu = (menus: Array<any>) => {
    return menus.map((menu, index) => {
      if (menu.title !== null) {
        return <AccordionMenu key={index} openIndex={openIndex} index={index} title={menu.title} sub_menu={menu.sub_menu} sub_icon={menu.sub_icon} multi_menu={menu.multi_menu} open={openIndex === index} setOpen={setOpen} allClosed={openIndex === null} handleRedirect={handleRedirect} />
      } else if (menu.multi_menu !== null) {
        return menu.multi_menu.map((multi: any, ii: number) => (
          <AccordionMenu key={ii} openIndex={openIndex} index={index * 10 + ii} title={multi.title} sub_menu={multi.sub_menu} sub_icon={multi.sub_icon} multi_menu={multi.multi_menu} open={openIndex === index * 10 + ii} setOpen={setOpen} allClosed={openIndex === null} handleRedirect={handleRedirect} />
        ))
      } else {
        return <></>
      }
    })
  }

  const handleRedirect = (title: string) => {
    switch (title) {
      case "MOOIMOM Affiliate Program":
        if (userData.user_id === undefined || userData.token === undefined || userData.token === "" ) {
          window.open("/login?redirect=https://affiliate.mooimom.id", "_blank");
        } else {
          window.open("https://affiliate.mooimom.id?uid=" + userData.token, "_blank");
        }
        break;

      default:
        break
    }
  }

  useEffect(() => {
    setUserData(getUser());
  }, [getUser, userInfo])

  return (
    <motion.footer
      className="p-0 m-0 bg-mooimom"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true }}
      variants={motionVariants}
    >
      <div className="container pt-12 pb-20 text-white md:pb-12 font-poppins">
        <div className="justify-between hidden grid-cols-4 md:flex">
          {subMenu.map((menu, index) => (
            <div key={index} className={`${index === subMenu.length - 1 ? "flex justify-end" : ""}`}>
              {menu.title && (
                <div>
                  <div className="text-base font-semibold">{menu.title}</div>
                  <div className="mt-4 space-y-4">
                    {menu.sub_menu && menu.sub_menu.map((sub, index) => (
                      <div className="text-xs" key={index}>
                        {sub.link !== null ? sub.link !== "#" ? (
                          <Link href={sub.link}>{sub.title}</Link>
                        ) : (
                          <div className="cursor-pointer" onClick={() => handleRedirect(sub.title)}>{sub.title}</div>
                        ) : <>{sub.title}</>}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {menu.multi_menu && (
                <div>
                  {render_multi_menu(menu.multi_menu)}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="md:hidden">
          {render_mobile_menu(subMenu)}
        </div>
        <hr className="my-8 bg-white border-t" />
        <div className="items-center justify-between md:flex">
          <div className="items-center md:flex md:space-x-8">
            <div className="text-base font-semibold text-center md:text-start">Follow us</div>
            <div className="flex items-center justify-center mt-4 space-x-8 md:justify-start md:mt-0">
              <Link href="https://www.instagram.com/mooimom.id/">
                <Image src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/instagram.png"} alt="instagram icon" width={32} height={32} quality={100} />
              </Link>
              <Link href="https://www.facebook.com/mooimom.id">
                <Image src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/facebook.png"} alt="facebook icon" width={32} height={32} quality={100} />
              </Link>
              <Link href="https://line.me/R/ti/p/%40mau7665r">
                <Image src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/line.png"} alt="line icon" width={32} height={32} quality={100} />
              </Link>
              <Link href="https://www.youtube.com/channel/UCrRvWHrQqd_lwAAwo-A2xMg">
                <Image src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/youtube.png"} alt="youtube icon" width={32} height={32} quality={100} />
              </Link>
            </div>
          </div>
          <div className="mt-8 text-xs text-center md:text-start md:mt-0">
            @ Copyright MOOIMOM PTE. LTD - All Rights Reserved
          </div>
        </div>
      </div>
    </motion.footer>
  )
}

export default Footer
