import { useEffect, useRef, useState } from "react";
import Image from 'next/image';
import { AccordionMenuItemProps } from "./constants"
import { useRouter } from "next/router";
import Link from "next/link";


const AccordionMenu = (props: AccordionMenuItemProps) => {
  const router = useRouter();
  const [isOpened, setOpened] = useState<boolean>(false)
  const [height, setHeight] = useState<string>("0px")
  const contentElement = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleRouteChange = () => {
      setOpened(false);
      props.setOpen(props.index, false);
      setHeight("0px");
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    };
  }, [router.events, props]);

  useEffect(() => {
    if (props.index === props.openIndex) {
      setOpened(true)
      setHeight(`${contentElement.current!.scrollHeight}px`)
    } else {
      setOpened(false)
      setHeight("0px")
    }
  }, [props.openIndex, props.index])

  const HandleOpening = () => {
    setOpened(!isOpened)
    props.setOpen(props.index, !isOpened)
    setHeight(!isOpened ? `${contentElement.current!.scrollHeight}px` : "0px")
  }

  return (
    <div onClick={HandleOpening} className="mb-8">
      <div className="flex justify-between mb-2 pb-2 cursor-pointer">
        <h5 className={`text-white ${isOpened || props.allClosed ? "" : "opacity-30"}`}>{props.title}</h5>
        <div className={`transition-transform ease-in-out duration-300 transform menu-arrow ${isOpened ? "rotate-0" : "-rotate-90 opacity-30"}`} />
      </div>
      <div
        ref={contentElement}
        style={{ height: height }}
        className="overflow-hidden transition-all duration-300 space-y-4"
      >
        {props.sub_menu && props.sub_menu.map((sub, index) => (
          <div className="text-xs" key={index}>
            {sub.link !== null ? sub.link !== "#" ? (
              <Link href={sub.link}>{sub.title}</Link>
            ) : (
              <div className="cursor-pointer" onClick={() => props.handleRedirect(sub.title)}>{sub.title}</div>
            ) : <>{sub.title}</>}
          </div>
        ))}
        {props.sub_icon && (
          <div className="grid grid-cols-4 gap-x-6 gap-y-4 w-fit">
            {props.sub_icon.map((icon, index) => (
              <Image src={icon} alt="payment icon" key={index} width={50} height={21} quality={100} className="" />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default AccordionMenu
