import { Variants } from "framer-motion";

export const CURRENCY_OPTIONS = {
  style: 'decimal',  // Other options: 'currency', 'percent', etc.
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
};

export const motionVariants: Variants = {
  // offscreen: {
  //   y: 100
  // },
  // onscreen: {
  //   y: 0,
  //   transition: {
  //     type: "spring",
  //     bounce: 0.4,
  //     duration: 0.8
  //   }
  // }
};
